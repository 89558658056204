
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import NavigationMixin from './mixins/navigation.mixin';
import Badge from '@/components/Badge.vue';

@Component({
  components: {
    Badge,
  },
})
export default class NavigationTabs extends mixins(NavigationMixin) {}
