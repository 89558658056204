
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { AxiosError } from 'axios';
import GButton from '@/components/gsk-components/GskButton.vue';
import GTextField from '@/components/gsk-components/GskTextfield.vue';
import Badge from '@/components/Badge.vue';
import { NotificationsObject, NotificationsPayload } from '@/types/notifications.types';
import { UINavigationItem } from '@/types';
import { NotificationsModule } from '@/store/modules/notifications.module';
import { RouteNames } from '@/constants';
import GridCell from '@/components/grid/GridCell';
import Grid from '@/components/grid/Grid';
import NavigationList from '@/components/NavigationList.vue';
import NavigationTabs from '@/components/NavigationTabs.vue';
import GCheckboxGroup from '@/components/gsk-components/GskCheckboxGroup.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import { getQuery, updateQuery } from '@/utils/routing';
import { SelectOption } from '@/components/form/form.types';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';

@Component({
  components: {
    GButton,
    NavigationList,
    Promised,
    Badge,
    GTextField,
    Grid,
    GridCell,
    NavigationTabs,
    GCheckboxGroup,
  },
})
export default class NotificationCenterView extends Vue {
  notifications: Promise<NotificationsObject> | null = null;

  public requestCount = 0;
  public notificationCount = 0;

  public approvalTypeFilterValue = [];

  get placeholder(): string {
    return this.isRequestTab ? 'Search requests' : 'Search updates';
  }

  get approvalTypeFilterOptions() {
    return NotificationsModule.allApprovalRequestTypeData || [];
  }

  get filterValue() {
    return getQuery(this, 'f', { toArray: true, toNumber: false });
  }
  set filterValue(v) {
    updateQuery(this, 'f', v).catch(() => 'noop');
  }

  get filterOptions(): Readonly<SelectOption[]> {
    return EnumsModule.enumsSelectOptions.notificationFilter;
  }

  public get tabs(): UINavigationItem[] {
    const tabs = [
      {
        text: 'Updates',
        key: 'notifications',
        route: {
          name: RouteNames.NotificationTable,
          params: { type: 'notifications' },
          query: this.$route.query,
        },
        props: {
          icon: 'information',
        },
        count: this.notificationCount,
      },
      {
        text: 'Requests',
        key: 'requests',
        route: {
          name: RouteNames.NotificationTable,
          params: { type: 'requests' },
          query: this.$route.query,
        },
        props: {
          icon: 'send',
        },
        count: this.requestCount,
      },
    ];
    return tabs;
  }

  // results per page
  public results = 10;
  // current page
  public page = 1;
  public totalResults = 0;

  public q = '';

  public get query(): string {
    const query = this.$route.query.search || '';
    if (typeof query === 'string') {
      return query;
    }
    if (query.length === 0) {
      return '';
    }
    return query[0] || '';
  }

  public set query(q: string) {
    this.$router.push({
      path: this.$route.params.type,
      query: { search: q },
    });
  }

  public get tabBarProps() {
    return {
      noripple: true,
    };
  }

  public get isRequestTab(): boolean {
    return this.tab.key === 'requests';
  }

  public get approvalRequestTypes(): number[] {
    return this.isRequestTab? this.approvalTypeFilterValue.map(Number): [];
  }

  public get tab(): UINavigationItem {
    const type = this.$route.params.type;
    return this.tabs.find(t => t.key === type) ?? this.tabs[0];
  }

  @Watch('tab')
  handleTabChange(tab: UINavigationItem) {
    this.page = 1;
    this.load(this.isRequestTab, this.query, this.results, this.page, this.approvalRequestTypes);
  }

  handlePaginationChange(results: number) {
    this.page = 1;
    this.load(this.isRequestTab, this.query, results, this.page, this.approvalRequestTypes);
  }

  backOnePage() {
    if (this.page > 1) {
      this.page = this.page - 1;
      this.load(this.isRequestTab, this.query, this.results, this.page, this.approvalRequestTypes);
    }
  }

  forwardOnePage() {
    const maxPage = this.totalResults / this.results;
    if (this.page < maxPage) {
      this.page = this.page + 1;
      this.load(this.isRequestTab, this.query, this.results, this.page, this.approvalRequestTypes);
    }
  }

  onSearch() {
    if (this.q === '') {
      this.$router.push({
        path: this.$route.params.type,
      });
    } else {
      this.$router.push({
        path: this.$route.params.type,
        query: { search: this.q },
      });
    }
  }

  @Watch('$route.query.search', { immediate: true })
  onQueryChange(query: string) {
    if (this.q !== query) {
      this.q = query || '';
    }
    this.page = 1;
    this.load(this.isRequestTab, query, this.results, this.page, this.approvalRequestTypes);
  }

  @Watch('approvalTypeFilterValue') 
  onRequestTypeChange(){
    this.load(this.isRequestTab, this.query, this.results, this.page, this.approvalRequestTypes);
  }

  load(requestsOnly: boolean, query: string, results: number, page: number, approvalRequestTypes: number[]) {
    let filter = this.filterValue;
    if (!this.isRequestTab) {
      filter = [];
    }
    console.log('load code');
    console.log(query);
    const payload: NotificationsPayload = {
      filter,
      requestsOnly: requestsOnly,
      query: query,
      results: results,
      page: page,
      approvalRequestTypes,
    };
    this.notifications = NotificationsModule.getNotifications(payload)
      .then(r => {
        const mod = NotificationsModule.notifications;
        this.notificationCount = mod.unreadNotificationCount;
        this.requestCount = mod.unreadRequestCount;
        this.totalResults = mod.totalQueryCount;
        return r;
      })
      .catch((e: AxiosError) => {
        // format and re-throw error so vue-promise catches
        if (e && e.response) {
          throw e.response.data.message;
        }
        throw e;
      });
  }
  async created() {
    await NotificationsModule.allApprovalRequestTypes();
    this.load(this.isRequestTab, this.query, this.results, this.page, this.approvalRequestTypes);
  }
}
